// ----------------------------------------------------------------------

export default function Container({ theme }) {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {},
      },
    },
  }
}

import { kea } from 'kea'
import axios from 'src/utils/axios'

export const buildingsLogic = kea({
  path: () => ['buildings'],
  actions: {
    setBuildings: buildings => ({ buildings }),
    clear: true,
  },
  loaders: {
    buildings: {
      getBuildings: _getBuildings,
    },
  },
  listeners: ({ actions }) => ({
    clear: _ => actions.setBuildings(null),
  }),
  reducers: {
    buildings: {
      setBuildings: (_, { buildings }) => buildings,
    },
  },
  selectors: {
    sortedBuildings: [
      selectors => [selectors.buildings],
      buildings => buildings?.sort(buildingSortName),
    ],
  },
  defaults: {
    buildings: [],
  },
})

export async function _getBuildings() {
  const response = await axios.get('buildings')
  return response.data
}

async function _getBuilding(buildingId) {
  if (!buildingId) throw Error('Building ID cannot be empty')
  const response = await axios.get(`buildings/${buildingId}`)
  return response.data
}

export const buildingSortName = (a, b) => (a.name > b.name ? 1 : -1)

import { makeStyles } from '@material-ui/core/styles'
import { useActions, useValues } from 'kea'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import LoadingScreen from 'src/components/LoadingScreen'
import { appLogic } from 'src/logic/app'
import { buildingsLogic } from 'src/logic/buildings'
import { framesLogic } from 'src/logic/frames'
import { noticesLogic } from 'src/logic/notices'
import { noticeTagsLogic } from 'src/logic/noticeTags'
import { userLogic } from 'src/logic/user'
import NavBar from './NavBar'
import TopBar from './TopBar'

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
  },
  main: {
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 40,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      paddingTop: APP_BAR_DESKTOP + 40,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}))

// ----------------------------------------------------------------------

AppLayout.propTypes = {
  children: PropTypes.node,
}

function AppLayout({ children }) {
  const classes = useStyles()
  const [openNav, setOpenNav] = useState(false)
  const { buildings } = useValues(buildingsLogic)
  const { frames } = useValues(framesLogic)
  const { notices } = useValues(noticesLogic)
  const { noticeTags } = useValues(noticeTagsLogic)
  const { initialise } = useActions(appLogic)
  const { appReady, appReadyLoading } = useValues(appLogic)
  const { isAuthenticated } = useValues(userLogic)

  useEffect(() => {
    if (isAuthenticated && !(buildings && frames && notices && noticeTags)) initialise()
  }, [isAuthenticated])

  if (appReadyLoading || appReady === undefined) {
    return <LoadingScreen />
  } else {
    return (
      <div className={classes.root}>
        <TopBar onOpenNav={() => setOpenNav(true)} />
        <NavBar onCloseNav={() => setOpenNav(false)} isOpenNav={openNav} />

        <div className={classes.main}>{children}</div>
      </div>
    )
  }
}

export default AppLayout

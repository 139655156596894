import { kea } from 'kea'
import axios from 'src/utils/axios'

export const noticeTagsLogic = kea({
  path: () => ['notice_tags'],
  actions: {
    addTag: tag => ({ tag }),
    setNoticeTags: tags => ({ tags }),
    clear: true,
  },
  loaders: {
    noticeTags: {
      // getTags: _getTags,
    },
  },
  listeners: ({ actions }) => ({
    clear: _ => actions.setNoticeTags(null),
  }),
  defaults: {},
  reducers: {
    noticeTags: {
      addTag: (state, { tag }) => [...state, tag],
      setNoticeTags: (_, { tags }) => tags,
    },
  },
  selectors: {
    // tagsDict: [
    //   selectors => [selectors.tags],
    //   tags => tags?.reduce((acc, tag) => ({ ...acc, [tag.id]: tag }), {}),
    // ],
  },
})

export async function _getNoticeTags() {
  const response = await axios.get('/contents/tags')
  return response.data
}

async function _createTag(tag) {
  const response = await axios.post('/contents/tags', tag)
  return response.data
}

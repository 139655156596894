import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import AuthProtect from 'src/components/Auth/AuthProtect'
import AppLayout from 'src/layouts/AppLayout'
import { PATH_DASHBOARD } from './paths'

// ----------------------------------------------------------------------
const Redirect404 = () => <Redirect to="/404" />

const DashbaordRoutes = {
  path: PATH_DASHBOARD.root,
  guard: AuthProtect,
  layout: AppLayout,
  routes: [
    {
      exact: true,
      path: PATH_DASHBOARD.root,
      component: lazy(() => import('src/views/dashboard/DashboardView')),
    },
    {
      component: Redirect404,
    },
  ],
}

export default DashbaordRoutes

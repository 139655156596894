import React from 'react'
import PropTypes from 'prop-types'
import { MAvatar } from 'src/theme'
import createAvatar from 'src/utils/createAvatar'
import { useValues } from 'kea'
import { userLogic } from 'src/logic/user'

// ----------------------------------------------------------------------

MyAvatar.propTypes = {
  className: PropTypes.string,
}

function MyAvatar({ className, ...other }) {
  const { user } = useValues(userLogic)

  return (
    <MAvatar
      src={user?.photoURL}
      alt={`${user?.firstName} ${user?.lastName}`}
      color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color}
      className={className}
      {...other}
    >
      {createAvatar(`${user?.firstName} ${user?.lastName}`).name}
    </MAvatar>
  )
}

export default MyAvatar

import AdapterDateFns from '@material-ui/lab/AdapterDateFns'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import { createBrowserHistory } from 'history'
import { getContext, resetContext } from 'kea'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import NotistackProvider from 'src/components/NotistackProvider'
import ScrollToTop from 'src/components/ScrollToTop'
import { keaConfig } from 'src/logic/keaConfig'
import routes, { renderRoutes } from 'src/routes'
import { ThemeConfig } from './theme'

export let history = createBrowserHistory()

resetContext(keaConfig)

function App() {
  const store = getContext().store

  return (
    <Provider store={store}>
      <ThemeConfig>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <NotistackProvider>
            <HelmetProvider>
              <Router history={history}>
                <ScrollToTop />
                {renderRoutes(routes)}
              </Router>
            </HelmetProvider>
          </NotistackProvider>
        </LocalizationProvider>
      </ThemeConfig>
    </Provider>
  )
}

export default App

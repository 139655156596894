import { loadersPlugin } from 'kea-loaders'
import localStoragePlugin from 'kea-localstorage'
import { appLogic } from './app'

export const keaConfig = {
  createStore: {},
  plugins: [
    localStoragePlugin({
      storageEngine: window.localStorage,
      prefix: '[kea]',
      separator: '_',
    }),
    loadersPlugin({
      // Called when any loader is started
      // onStart({ logic, reducerKey, actionKey }) {
      //   // start a global spinner
      // },
      // // Called when any loader was successful
      // onSuccess({ response, logic, reducerKey, actionKey }) {
      //   // stop that global spinner
      // },
      // // Called when the listener throws an error
      // // Feel free to alert the user in a nicer way,
      // // for example by displaying a notification.
      // // Also connect this to your bug tracking software.
      // onFailure({ error, logic, reducerKey, actionKey }) {
      //   // if (error.message === "Request failed with status code 401") {
      //   //   history.push({
      //   //     pathname: "/login",
      //   //     state: { from: window.location.pathname },
      //   //   })
      //   // } else {
      //   //   console.error(`Error in ${actionKey} for ${reducerKey}:`, error)
      //   //   message.error(error.message)
      //   // }
      // },
      onFailure({ error, logic, reducerKey, actionKey }) {
        if (error.status === 401) appLogic.actions.logout()

        console.error(`Error in ${actionKey} for ${reducerKey}:`, error)
      },
    }),
  ],
}

import React from 'react'
import { useValues } from 'kea'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { PATH_USER } from 'src/routes/paths'
import LoadingScreen from 'src/components/LoadingScreen'
import { userLogic } from 'src/logic/user'
import { useLocation } from 'react-router-dom'

// ----------------------------------------------------------------------

AuthProtect.propTypes = {
  children: PropTypes.node,
}

function AuthProtect({ children }) {
  const { isAuthenticated, isAuthenticatedLoading } = useValues(userLogic)
  const location = useLocation()

  if (isAuthenticatedLoading) {
    return <LoadingScreen />
  } else if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: PATH_USER.login,
          state: { from: location.pathname },
        }}
      />
    )
  } else {
    return <>{children}</>
  }
}

export default AuthProtect

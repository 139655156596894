import homeOutline from '@iconify-icons/eva/home-outline'
import { Icon } from '@iconify/react'
import React from 'react'
import { PATH_DASHBOARD, PATH_NOTICE } from 'src/routes/paths'
import { MIcon } from 'src/theme'
import emailMultipleOutline from '@iconify-icons/mdi/email-multiple-outline'

// ----------------------------------------------------------------------

const path = name => `/static/icons/navbar/${name}.svg`

const ICONS = {
  authenticator: <MIcon src={path('ic_authenticator')} />,
  blog: <MIcon src={path('ic_blog')} />,
  calendar: <MIcon src={path('ic_calendar')} />,
  cart: <MIcon src={path('ic_cart')} />,
  charts: <MIcon src={path('ic_charts')} />,
  chat: <MIcon src={path('ic_chat')} />,
  components: <MIcon src={path('ic_components')} />,
  dashboard: <MIcon src={path('ic_dashboard')} />,
  editor: <MIcon src={path('ic_editor')} />,
  elements: <MIcon src={path('ic_elements')} />,
  error: <MIcon src={path('ic_error')} />,
  mail: <MIcon src={path('ic_mail')} />,
  map: <MIcon src={path('ic_map')} />,
  page: <MIcon src={path('ic_page')} />,
  user: <MIcon src={path('ic_user')} />,
  upload: <MIcon src={path('ic_upload')} />,
  copy: <MIcon src={path('ic_copy')} />,
  carousel: <MIcon src={path('ic_carousel')} />,
  language: <MIcon src={path('ic_language')} />,
}

const navConfig = [
  {
    subheader: 'main',
    items: [
      {
        title: 'dashboard',
        icon: <Icon icon={homeOutline} />,
        href: PATH_DASHBOARD.root,
        // items: [
        //   {
        //     title: 'dashboard',
        //     href: PATH_APP.dashboard,
        //   },
        //   // {
        //   //   title: 'building',
        //   //   href: PATH_BUILDING.buildings,
        //   // },
        // ],
      },
      {
        title: 'notices',
        icon: <Icon icon={emailMultipleOutline} />,
        href: PATH_NOTICE.notices,
        // items: [
        //   {
        //     title: 'dashboard',
        //     href: PATH_APP.dashboard,
        //   },
        //   // {
        //   //   title: 'building',
        //   //   href: PATH_BUILDING.buildings,
        //   // },
        // ],
      },
      // {
      //   title: 'buildings',
      //   icon: <Icon icon={buildingIcon} />,
      //   href: PATH_BUILDING.buildings,
      //   items: [
      //     {
      //       title: 'dashboard',
      //       href: PATH_APP.dashboard,
      //     },
      //     {
      //       title: 'building',
      //       href: '/building',
      //     },
      //   ],
      // },
    ],
  },
]

export default navConfig

import { kea } from 'kea'
import { PATH_USER } from 'src/routes/paths'
import axios from 'src/utils/axios'
import { history } from 'src/App'

export const userLogic = kea({
  path: () => ['auth'],
  actions: {
    setUser: user => ({ user }),
    setAccessToken: accessToken => ({ accessToken }),
    setRefreshToken: refreshToken => ({ refreshToken }),
    setAccount: account => ({ account }),
    logout: true,
    initialise: true,
    clear: true,
  },
  loaders: {
    account: {
      getAccount: _getAccount,
    },
  },
  reducers: {
    user: [
      { persist: true },
      {
        setUser: (_, { user }) => user,
        // logout: _ => null,
      },
    ],
    account: [
      { persist: true },
      {
        getAccountSuccess: (_, { account }) => account,
        setAccount: (_, { account }) => account,
        // logout: _ => null,
      },
    ],
    accessToken: [
      { persist: true },
      {
        setAccessToken: (_, { accessToken }) => accessToken,
        // logout: _ => null,
      },
    ],
    refreshToken: [
      { persist: true },
      {
        setRefreshToken: (_, { refreshToken }) => refreshToken,
        // logout: _ => null,
      },
    ],
  },
  listeners: ({ actions, values }) => ({
    initialise: _ => {
      axios.defaults.headers.common.Authorization = `Bearer ${values.accessToken}`
    },
    setAccessToken: ({ accessToken }) => {
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    },
    // logout: _ => {
    //   delete axios.defaults.headers.common.Authorization
    //   history.push(PATH_USER.login)
    // },
    clear: _ => {
      actions.setUser(null)
      actions.setAccessToken(null)
      actions.setRefreshToken(null)
      actions.setAccount(null)
      delete axios.defaults.headers.common.Authorization
      history.push(PATH_USER.login)
    },
  }),
  selectors: {
    isAuthenticated: [selectors => [selectors.accessToken], token => token],
  },
  defaults: {
    account: null,
  },
  events: ({ actions }) => ({
    afterMount: [actions.initialise],
  }),
})

/**
 * Make the LOGIN HTTP call, if successful, will set the session
 * with the returned access token and return user object
 */
export async function _login(email, password) {
  const response = await axios.post('/auth/login/email', { email, password })
  const user = response.data
  const accessToken = response.headers['authorization']?.substring(7)
  const refreshToken = response.headers['x-refresh-token']?.substring(7)
  return { user, accessToken, refreshToken }
}

export async function _ssoExchangeCode(provider, code) {
  const response = await axios.post(`/auth/login/oidc/${provider}/code`, { code })
  const user = response.data
  const accessToken = response.headers['authorization']?.substring(7)
  const refreshToken = response.headers['x-refresh-token']?.substring(7)
  return { user, accessToken, refreshToken }
}

export async function _getAccount() {
  const response = await axios.get('/accounts/self')
  return response.data
}

export async function _sendResetPasswordEmail(email) {
  if (!email) throw Error('Email should not be empty when reseting password')
  const response = await axios.post(`/support/forgotten-password?email=${email}`)
  return response.data
}

export async function _resetPassword(token, password) {
  if (!token || !password) throw Error('Reset password failed')
  const response = await axios.post(`/support/update-password`, password, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'text/plain',
    },
  })
  return response.data
}

import { kea } from 'kea'
import axios from 'src/utils/axios'

export const framesLogic = kea({
  path: () => ['frames'],
  actions: {
    setFrames: frames => ({ frames }),
    addNoticeToFrame: (frameId, noticeId) => ({ frameId, noticeId }),
    removeNoticeFromFrames: (frameId, noticeId) => ({ frameId, noticeId }),
    clear: true,
  },
  loaders: {},
  listeners: ({ actions }) => ({
    clear: _ => actions.setFrames(null),
  }),
  reducers: {
    frameDict: {
      setFrames: (_, { frames }) =>
        frames?.reduce((acc, frame) => ({ ...acc, [frame.id]: frame }), {}) ?? null,
      addNoticeToFrame: (current, { frameId, noticeId }) => ({
        ...current,
        [frameId]: {
          ...current[frameId],
          contents: [...current[frameId].contents, noticeId],
        },
      }),
      removeNoticeFromFrames: (current, { frameId, noticeId }) => ({
        ...current,
        [frameId]: {
          ...current[frameId],
          contents: current[frameId].contents.filter(c => c !== noticeId),
        },
      }),
    },
  },
  selectors: {
    frames: [selectors => [selectors.frameDict], dict => (dict ? Object.values(dict) : [])],
  },
})

export async function _getAllFrames() {
  const res1 = await axios.get('/buildings')
  const buildings = res1.data
  const frameIds = buildings.reduce((acc, v) => [...acc, ...v.frames], [])
  const frames = await _getFrames(frameIds)
  return { buildings, frames }
}

export async function _getFrames(frameIds) {
  if (!frameIds) throw Error('Frame IDs cannot be empty')
  const response = await axios.post('/frames/get-multiple', frameIds)
  return response.data
}

async function _getFrame(frameId) {
  if (!frameId) throw Error('Frame ID cannot be empty')
  const response = await axios.get(`/frames/${frameId}`)
  return response.data
}

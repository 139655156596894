export function path(root, sublink) {
  return `${root}${sublink}`
}

const ROOTS = {
  users: '/users',
  dashboard: '/dashboard',
  buildings: '/buildings',
  frames: '/frames',
  notices: '/notices',
}

export const PATH_USER = {
  root: ROOTS.users,
  login: path(ROOTS.users, '/login'),
  sso: path(ROOTS.users, '/sso/:provider'),
  emailResetPassword: path(ROOTS.users, '/email-reset-password'),
  resetPassword: path(ROOTS.users, '/reset-password'),
}

export const PATH_DASHBOARD = {
  root: ROOTS.dashboard,
}

export const PATH_BUILDING = {
  root: ROOTS.buildings,
  buildings: path(ROOTS.buildings, '/all'),
  building: path(ROOTS.buildings, '/:buildingId'),
}

export const PATH_FRAME = {
  root: ROOTS.frames,
  frame: path(ROOTS.frames, '/:frameId'),
}

export const PATH_NOTICE = {
  root: ROOTS.notices,
  notices: path(ROOTS.notices, '/all'),
  noticeCompose: path(ROOTS.notices, '/:noticeId/compose'),
}

import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import closeFill from '@iconify-icons/eva/close-fill'
import { MIconButton } from 'src/theme'
import { Icon } from '@iconify/react'
import { Stack, Button, Box, Container, Hidden, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { useActions } from 'kea'
import PropTypes from 'prop-types'
import Page from 'src/components/Page'
import {
  useLocation,
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from 'react-router-dom'
import { userLogic, _getAccount, _ssoAzureAd, _ssoExchangeCode, _ssoGoogle } from 'src/logic/user'
import { PATH_DASHBOARD, PATH_USER } from 'src/routes/paths'
import LoadingScreen from 'src/components/LoadingScreen'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  header: {
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      padding: theme.spacing(7, 5, 0, 7),
    },
  },
  content: {
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0),
  },
}))

function SsoLoginHandler({ history }) {
  const classes = useStyles()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const { provider } = useParams()
  const code = query.get('code')
  const from = location?.state?.from ?? PATH_DASHBOARD.root
  const [forbiddenError, setForbiddenError] = useState(false)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { setUser, setAccessToken, setRefreshToken, setAccount } = useActions(userLogic)

  const SnackbarAction = key => (
    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
      <Icon icon={closeFill} />
    </MIconButton>
  )

  function providerName(provider) {
    if (provider === 'google') return 'Google'
    else if (provider === 'azure-ad') return 'Azure Active Directory'
    else return ''
  }

  async function handleSso(provider, code) {
    try {
      const { user, accessToken, refreshToken } = await _ssoExchangeCode(provider, code)
      setAccessToken(accessToken)
      setRefreshToken(refreshToken)
      await new Promise(resolve => setTimeout(resolve, 100))
      const account = await _getAccount()
      setAccount(account)
      setUser(user)
      enqueueSnackbar('Login success', {
        variant: 'success',
        action: SnackbarAction,
      })
      setTimeout(() => history.push(from), 500)
    } catch (error) {
      console.error(error, error.status, error.url, error.message)
      enqueueSnackbar('Login failed', {
        variant: 'error',
        action: SnackbarAction,
      })
      if (error.status === 403) setForbiddenError(true)
      else history.push(PATH_USER.login)
    }
  }

  useEffect(async () => {
    if (provider && code) await handleSso(provider, code)
  }, [provider, code])

  if (forbiddenError) {
    return (
      <Page title="Login | Affogato" className={classes.root}>
        <Container maxWidth="sm">
          <div className={classes.content}>
            <Stack sx={{ mb: 5, display: 'flex', alignItems: 'center' }}>
              <Box sx={{ flexGrow: 1, paddingBottom: 4 }}>
                <Typography variant="h3" gutterBottom>
                  Account not registered
                </Typography>
                <Typography sx={{ color: 'text.secondary', textAlign: 'justify' }}>
                  {`The ${providerName(
                    provider
                  )} account you are using to sign in is not registered in the Affogato system. 
                  `}
                  <br />
                  {'Kindly contact '}
                  <a href="mailto:support@30secodnsgroup.com">support@30secondsgroup.com</a>
                  {' to request access to the system.'}
                </Typography>
              </Box>
              <Button size="large" variant="contained" fullWidth href={PATH_USER.login}>
                Back to login page
              </Button>
            </Stack>
          </div>
        </Container>
      </Page>
    )
  } else {
    return <LoadingScreen />
  }
}

SsoLoginHandler.propTypes = {
  history: PropTypes.object.isRequired,
}

export default SsoLoginHandler

import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'
import 'lazysizes/plugins/object-fit/ls.object-fit'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import React from 'react'
import ReactDOM from 'react-dom'
import 'typeface-open-sans'
// import 'src/mock'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import packageInfo from '../package.json'

global.app_version = packageInfo.version

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { useActions, useValues } from 'kea'
import { useCallback } from 'react'
import { themeLogic } from 'src/logic/theme'

function useSettings() {
  const themeDirection = 'ltr'
  const { switchMode } = useActions(themeLogic)
  const { themeMode } = useValues(themeLogic)
  const isLight = themeMode === 'light'

  const handleChangeDirection = () => {}
  const handleToggleTheme = useCallback(() => switchMode(isLight ? 'dark' : 'light'), [isLight])
  const handleChangeTheme = event => switchMode(event.target.value)

  return {
    // Mode
    themeMode: themeMode,
    toggleMode: handleToggleTheme,
    selectMode: handleChangeTheme,
    // Direction
    themeDirection,
    selectDirection: handleChangeDirection,
  }
}

export default useSettings

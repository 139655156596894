import buildingIcon from '@iconify-icons/bi/building'
import { Icon } from '@iconify/react'
import { Box, Drawer, Hidden, List, ListSubheader, Typography } from '@material-ui/core'
import { alpha, makeStyles } from '@material-ui/core/styles'
import { useValues } from 'kea'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { generatePath } from 'react-router'
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom'
import Logo from 'src/components/Logo'
import Scrollbars from 'src/components/Scrollbars'
import { buildingsLogic } from 'src/logic/buildings'
import { userLogic } from 'src/logic/user'
import { PATH_BUILDING } from 'src/routes/paths'
import MenuLinks from './config'
import NavItem from './NavItem'

const DRAWER_WIDTH = 300

const useStyles = makeStyles(theme => {
  const isLight = theme.palette.mode === 'light'

  return {
    drawer: {
      [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH,
      },
    },
    drawerPaper: {
      width: DRAWER_WIDTH,
      background: theme.palette.background.default,
    },
    subHeader: {
      ...theme.typography.overline,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(5),
      color: theme.palette.text.primary,
    },
    account: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2, 2.5),
      margin: theme.spacing(1, 2.5, 5),
      borderRadius: theme.shape.borderRadiusSm,
      background: theme.palette.grey[isLight ? 200 : 800],
    },
    doc: {
      padding: theme.spacing(2.5),
      borderRadius: theme.shape.borderRadiusMd,
      backgroundColor: isLight
        ? alpha(theme.palette.primary.main, 0.08)
        : theme.palette.primary.lighter,
    },
  }
})

// ----------------------------------------------------------------------

function reduceChild({ array, item, pathname, level }) {
  const key = item.href + level

  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false,
    })

    array = [
      ...array,
      <NavItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={item.title}
        open={Boolean(match)}
      >
        {renderNavItems({
          pathname,
          level: level + 1,
          items: item.items,
        })}
      </NavItem>,
    ]
  } else {
    array = [
      ...array,
      <NavItem
        key={key}
        level={level}
        href={item.href}
        icon={item.icon}
        info={item.info}
        title={item.title}
      />,
    ]
  }
  return array
}

function renderNavItems({ items, pathname, level = 0 }) {
  return (
    <List disablePadding>
      {items.reduce((array, item) => reduceChild({ array, item, pathname, level }), [])}
    </List>
  )
}

NavBar.propTypes = {
  onCloseNav: PropTypes.func,
  isOpenNav: PropTypes.bool,
}

function NavBar({ isOpenNav, onCloseNav }) {
  const classes = useStyles()
  const { pathname } = useLocation()
  const { account, user } = useValues(userLogic)
  const { sortedBuildings } = useValues(buildingsLogic)
  const buildingItems = {
    title: 'buildings',
    icon: <Icon icon={buildingIcon} />,
    href: PATH_BUILDING.root,
    items: [
      {
        title: 'all',
        href: PATH_BUILDING.buildings,
      },
      ...(sortedBuildings?.map(building => ({
        title: building.name,
        href: generatePath(PATH_BUILDING.building, { buildingId: building.id }),
      })) ?? []),
    ],
  }

  useEffect(() => {
    if (isOpenNav && onCloseNav) {
      onCloseNav()
    }
  }, [pathname])

  const renderContent = (
    <Scrollbars>
      <Box sx={{ px: 2.5, py: 3 }}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </Box>

      <div className={classes.account}>
        <Box sx={{ ml: 2 }}>
          <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
            {account?.name ?? '-'}
          </Typography>
        </Box>
      </div>

      {MenuLinks.map(list => (
        <List
          disablePadding
          key={list.subheader}
          subheader={
            <ListSubheader disableSticky disableGutters className={classes.subHeader}>
              {list.subheader}
            </ListSubheader>
          }
        >
          {renderNavItems({
            items: [...list.items, buildingItems],
            pathname: pathname,
          })}
        </List>
      ))}
    </Scrollbars>
  )

  return (
    <nav className={classes.drawer}>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={isOpenNav}
          variant="temporary"
          onClose={onCloseNav}
          classes={{ paper: classes.drawerPaper }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer open anchor="left" variant="persistent" classes={{ paper: classes.drawerPaper }}>
          {renderContent}
        </Drawer>
      </Hidden>
    </nav>
  )
}

export default NavBar

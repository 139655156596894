import { makeStyles } from '@material-ui/core/styles'
import NProgress from 'nprogress'
import React, { Fragment, lazy, Suspense, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import LoadingScreen from 'src/components/LoadingScreen'
import SsoLoginHandler from 'src/views/user/LoginView/SsoLoginHandler'
import BuildingRoutes from './BuildingRoutes'
import DashbaordRoutes from './DashboardRoutes'
import FrameRoutes from './FrameRoutes'
import NoticeRoutes from './NoticeRoutes'
import { PATH_DASHBOARD, PATH_USER } from './paths'
import UserRoutes from './UserRoutes'

const nprogressStyle = makeStyles(theme => ({
  '@global': {
    '#nprogress': {
      pointerEvents: 'none',
      '& .bar': {
        top: 0,
        left: 0,
        height: 2,
        width: '100%',
        position: 'fixed',
        zIndex: theme.zIndex.snackbar,
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 0 2px ${theme.palette.primary.main}`,
      },
      '& .peg': {
        right: 0,
        opacity: 1,
        width: 100,
        height: '100%',
        display: 'block',
        position: 'absolute',
        transform: 'rotate(3deg) translate(0px, -4px)',
        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`,
      },
    },
  },
}))

function RouteProgress(props) {
  nprogressStyle()

  NProgress.configure({
    speed: 500,
    showSpinner: false,
  })

  useEffect(() => {
    NProgress.done()
    return () => {
      NProgress.start()
    }
  }, [])

  return <Route {...props} />
}

export function renderRoutes(routes = []) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Component = route.component
          const Guard = route.guard || Fragment
          const Layout = route.layout || Fragment

          return (
            <RouteProgress
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                  </Layout>
                </Guard>
              )}
            />
          )
        })}
      </Switch>
    </Suspense>
  )
}

const RedirectAppDashboard = () => <Redirect to={PATH_DASHBOARD.root} />

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/Page404View')),
  },
  {
    exact: true,
    path: '/500',
    component: lazy(() => import('src/views/errors/Page500View')),
  },
  {
    exact: true,
    path: PATH_USER.login,
    component: lazy(() => import('src/views/user/LoginView')),
  },
  {
    exact: true,
    path: PATH_USER.sso,
    component: SsoLoginHandler,
  },
  {
    exact: true,
    path: PATH_USER.emailResetPassword,
    component: lazy(() => import('src/views/user/EmailResetPasswordView')),
  },
  {
    exact: false,
    path: PATH_USER.resetPassword,
    component: lazy(() => import('src/views/user/ResetPasswordView')),
  },
  BuildingRoutes,
  DashbaordRoutes,
  FrameRoutes,
  NoticeRoutes,
  UserRoutes,
  {
    exact: true,
    path: '/',
    component: RedirectAppDashboard,
  },
]

export default routes

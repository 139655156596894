import { kea } from 'kea'
import { buildingsLogic, _getBuildings } from 'src/logic/buildings'
import { framesLogic, _getFrames } from 'src/logic/frames'
import { noticesLogic, _getNotices } from 'src/logic/notices'
import { noticeTagsLogic, _getNoticeTags } from 'src/logic/noticeTags'
import { userLogic, _getAccount } from './user'

export const appLogic = kea({
  path: () => ['app'],
  connect: [userLogic, buildingsLogic, framesLogic, noticesLogic, noticeTagsLogic],
  actions: {
    logout: true,
  },
  loaders: {
    appReady: {
      initialise: async _ => {
        const account = await _getAccount()
        const buildings = await _getBuildings()
        const frameIds = buildings.map(building => building.frames).flat()
        const frames = await _getFrames(frameIds)
        const noticeIds = [...new Set(frames.map(frame => frame.contents).flat())]
        const notices = await _getNotices(noticeIds)
        const noticeTags = await _getNoticeTags()
        userLogic.actions.setAccount(account)
        buildingsLogic.actions.setBuildings(buildings)
        framesLogic.actions.setFrames(frames)
        noticesLogic.actions.setNotices(notices)
        noticeTagsLogic.actions.setNoticeTags(noticeTags)
        return true
      },
    },
  },
  listeners: {
    logout: _ => {
      buildingsLogic.actions.clear()
      framesLogic.actions.clear()
      noticesLogic.actions.clear()
      noticeTagsLogic.actions.clear()
      userLogic.actions.clear()
    },
  },
  reducers: {},
  selectors: {},
  defaults: {
    appReady: undefined,
  },
})

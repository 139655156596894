import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import AuthProtect from 'src/components/Auth/AuthProtect'
import AppLayout from 'src/layouts/AppLayout'
import { PATH_FRAME } from './paths'

const RedirectFrameDashboard = () => <Redirect to={PATH_FRAME.frame} />
const Redirect404 = () => <Redirect to="/404" />

const FrameRoutes = {
  path: PATH_FRAME.root,
  guard: AuthProtect,
  layout: AppLayout,
  routes: [
    {
      exact: true,
      path: PATH_FRAME.root,
      component: RedirectFrameDashboard,
    },
    {
      exact: true,
      path: PATH_FRAME.frame,
      component: lazy(() => import('src/views/frame/FrameView')),
    },
    {
      component: Redirect404,
    },
  ],
}

export default FrameRoutes

import axios from 'axios'
import { userLogic } from 'src/logic/user'

const serverUrl = process.env.REACT_APP_SERVER_API_URL

const axiosInstance = axios.create({ baseURL: serverUrl })

const _internalInstance = axios.create({ baseURL: serverUrl })

axiosInstance.interceptors.response.use(
  response => new Promise((resolve, reject) => setTimeout(() => resolve(response), 0)),
  error => {
    var customError = new Error()
    customError.status = error.response?.status
    customError.url = error.request?.responseURL
    customError.message = error.response?.data || 'Something went wrong'

    const blacklisted =
      error.config.url.startsWith('/auth') ||
      error.config.url.startsWith('/support/update-password')

    if (!blacklisted && error.response?.status === 401) {
      try {
        const refreshToken = userLogic?.values?.refreshToken
        if (refreshToken) {
          const config = error.config
          return _internalInstance
            .get('/auth/refresh', { headers: { 'X-REFRESH-TOKEN': `Bearer ${refreshToken}` } })
            .catch(error => Promise.reject(customError))
            .then(res => {
              const accessToken = res.headers['authorization']?.substring(7)
              const refreshToken = res.headers['x-refresh-token']?.substring(7)
              if (refreshToken) userLogic.actions.setRefreshToken(refreshToken)
              if (accessToken) {
                userLogic.actions.setAccessToken(accessToken)
                const headers = { ...config.headers, Authorization: `Bearer ${accessToken}` }
                const updatedConfig = { ...config, headers }
                return _internalInstance(updatedConfig).catch(error => Promise.reject(customError))
              }
            })
        } else {
          return Promise.reject(customError)
        }
      } catch (e) {
        return Promise.reject(customError)
      }
    } else {
      return Promise.reject(customError)
    }
  }
)

export default axiosInstance

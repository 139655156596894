import { kea } from 'kea'

export const themeLogic = kea({
  path: () => ['theme'],
  actions: {
    switchMode: mode => ({ mode }),
  },
  loaders: _ => ({}),
  defaults: {
    themeMode: 'dark',
  },
  reducers: {
    themeMode: [
      { persist: true },
      {
        switchMode: (_, { mode }) => mode,
      },
    ],
  },
})

import { kea } from 'kea'
import axios from 'src/utils/axios'
import { framesLogic } from './frames'

export const noticesLogic = kea({
  path: () => ['notices'],
  actions: {
    setNotices: notices => ({ notices }),
    createNotice: notice => ({ notice }),
    addNotice: notice => ({ notice }),
    updateNotice: notice => ({ notice }),
    updateNoticeStatus: (noticeId, status) => ({ noticeId, status }),
    removeNoticeFromFrames: (notice, frameIds) => ({ notice, frameIds }),
    clear: true,
  },
  loaders: {
    noticeDict: {
      createNotice: async ({ notice }) => {
        notice.frames.map(frameId => framesLogic.actions.addNoticeToFrame(frameId, notice.id))
        return notice
      },
      updateNotice: async ({ notice }) => {
        await _updateNotice(notice)
        return notice
      },
      updateNoticeStatus: async ({ noticeId, status }) => {
        await _updateNoticeStatus(noticeId, status)
        return { noticeId, status }
      },
    },
  },
  listeners: ({ actions }) => ({
    removeNoticeFromFrames: ({ notice, frameIds }) => {
      const updated = { ...notice, frames: notice.frames.filter(id => !frameIds.includes(id)) }
      actions.updateNotice(updated)
      frameIds.map(frameId => framesLogic.actions.removeNoticeFromFrames(frameId, notice.id))
    },
    clear: _ => actions.setNotices(null),
  }),
  reducers: {
    noticeDict: {
      createNoticeSuccess: (state, { noticeDict }) => ({ ...state, [noticeDict.id]: noticeDict }),
      addNotice: (state, { notice }) => ({ ...state, [notice.id]: notice }),
      updateNoticeSuccess: (state, { noticeDict }) => ({ ...state, [noticeDict.id]: noticeDict }),
      updateNoticeStatusSuccess: (state, { noticeDict: { noticeId, status } }) =>
        updateNoticeHelper(state, noticeId, { status }),
      setNotices: (_, { notices }) =>
        notices?.reduce((acc, notice) => ({ ...acc, [notice.id]: notice }), {}) ?? null,
      setNoticeDict: (_, { noticeDict }) => noticeDict,
    },
  },
  selectors: {
    notices: [
      selectors => [selectors.noticeDict],
      dict => (dict ? Object.values(dict).filter(n => n.status !== 'deleted') : []),
    ],
    activeNotices: [
      selectors => [selectors.notices],
      notices => notices?.filter(noticeFilterActive) ?? [],
    ],
    mostRecentNotices: [
      selectors => [selectors.activeNotices],
      notices => notices?.sort(noticeSortMostRecent)?.slice(0, 5),
    ],
  },
  defaults: {},
})

export async function _createNotice(notice) {
  if (!notice) throw Error('Notice cannot be empty')
  const response = await axios.post('/contents', notice)
  return response.data
}

export async function _getNotices(noticeIds) {
  if (!noticeIds) throw Error('Notice IDs cannot be empty')
  const response = await axios.post('/contents/get-multiple', noticeIds)
  return response.data
}

export async function _getNotice(noticeId) {
  if (!noticeId) throw Error('Notice ID cannot be empty')
  const response = await axios.get(`/contents/${noticeId}`)
  return response.data
}

export async function _uploadNoticeMedia(file) {
  const data = new FormData()
  data.append('dataFile', file)
  const response = await axios.post('/media/notice-images', data)
  return response.data
}

export async function _updateNotice(notice) {
  if (!notice && notice.id) throw Error('Notice cannot be empty')
  const { name, tag, body, duration, schedule, frames } = notice
  if (name && body && duration && schedule) {
    const payload = {
      name,
      tag,
      body,
      duration,
      schedule,
      frames,
    }
    const response = await axios.put(`/contents/${notice.id}`, payload)
    return response.data
  } else throw Error('Some elements are missing in the notice')
}

async function _updateNoticeStatus(noticeId, status) {
  if (!noticeId || !status) throw Error('Notice ID or notice status cannot be empty')
  const response = await axios.put(`/contents/${noticeId}/status`, { status })
  return response.data
}

function noticesSort(sort) {
  if (sort === 'Most Recent') return noticeSortMostRecent
  else if (sort === 'Least Recent') return noticeSortLeastRecent
  else if (sort === 'Name') return noticeSortName
  else return noticeSortMostRecent
}
export const noticeFilterActive = notice => notice.status === 'active'
export const noticeSortMostRecent = (a, b) => (a.lastUpdated < b.lastUpdated ? 1 : -1)
export const noticeSortLeastRecent = (a, b) => (a.lastUpdated < b.lastUpdated ? -1 : 1)
export const noticeSortName = (a, b) => (a.name > b.name ? 1 : -1)

function updateNoticeHelper(noticeDict, noticeId, fields) {
  const notice = noticeDict?.[noticeId]
  const updated = { ...notice, ...fields }
  return { ...noticeDict, [notice.id]: updated }
}
